import type { ReactNode } from "react";
import React, { createContext, useContext, useEffect } from "react";

import { LegalStructureEnum } from "../entities/legalStructure";
import { useNavigate } from "@remix-run/react";
import type { EnigmaBusiness } from "~/services/enigma.server";
import { useBusinessRoutes } from "~/hooks/useBusinessRoutes";

export enum AddBusinessSteps {
  BUSINESS_NAME = "BUSINESS_NAME",
  LEGAL_STRUCTURE = "LEGAL_STRUCTURE",
  FIND_BUSINESS = "FIND_BUSINESS",
  CLAIM_BUSINESS = "CLAIM_BUSINESS",
  ADDITIONAL_DETAIL = "ADDITIONAL_DETAIL",
  BUSINESS_CLAIMED = "BUSINESS_CLAIMED",
  EDIT_SEARCH = "EDIT_SEARCH",
  CONFIRM_BUSINESS = "CONFIRM_BUSINESS",
}

interface UseAddBusinessType {
  step: AddBusinessSteps | undefined;
  prevStep: AddBusinessSteps | undefined;
  handleNextStep: (nextStep: AddBusinessSteps | undefined) => void;
  handlePrevStep: () => void;
  resetStep: () => void;
  legalStructureItems: {
    id: string;
    value: LegalStructureEnum;
    label: string;
  }[];
  selectedBusiness: EnigmaBusiness;
  setSelectedBusiness: (business: EnigmaBusiness) => void;
}

const defaultContextValue: UseAddBusinessType = {
  step: AddBusinessSteps.BUSINESS_NAME,
  handleNextStep: () => {},
  handlePrevStep: () => {},
  resetStep: () => {},
  setSelectedBusiness: () => {},
  legalStructureItems: [],
  prevStep: undefined,
  selectedBusiness: {} as EnigmaBusiness,
};

const AddBusinessContext =
  createContext<UseAddBusinessType>(defaultContextValue);

interface AddBusinessProviderProps {
  children: ReactNode;
}

export const AddBusinessProvider: React.FC<AddBusinessProviderProps> = ({
  children,
}) => {
  const [step, setStep] = React.useState<AddBusinessSteps | undefined>(
    AddBusinessSteps.BUSINESS_NAME,
  );

  const [selectedBusiness, setSelectedBusiness] = React.useState(
    {} as EnigmaBusiness,
  );

  const navigate = useNavigate();
  const { businessRoutes } = useBusinessRoutes();

  const handleNextStep = (nextStep: AddBusinessSteps | undefined) => {
    setStep(nextStep);
  };

  const handlePrevStep = () => {
    if (step === AddBusinessSteps.BUSINESS_NAME) {
      navigate(businessRoutes.business.home);
      return;
    }
    if (step) {
      const previousStepMap: Record<
        AddBusinessSteps,
        AddBusinessSteps | undefined
      > = {
        [AddBusinessSteps.BUSINESS_NAME]: undefined,
        [AddBusinessSteps.LEGAL_STRUCTURE]: AddBusinessSteps.BUSINESS_NAME,
        [AddBusinessSteps.FIND_BUSINESS]: AddBusinessSteps.LEGAL_STRUCTURE,
        [AddBusinessSteps.CLAIM_BUSINESS]: AddBusinessSteps.FIND_BUSINESS,
        [AddBusinessSteps.CONFIRM_BUSINESS]: AddBusinessSteps.CLAIM_BUSINESS,
        [AddBusinessSteps.EDIT_SEARCH]: AddBusinessSteps.CLAIM_BUSINESS,
        [AddBusinessSteps.ADDITIONAL_DETAIL]: AddBusinessSteps.CLAIM_BUSINESS,
        [AddBusinessSteps.BUSINESS_CLAIMED]: AddBusinessSteps.CLAIM_BUSINESS,
      };
      const prevStep = previousStepMap[step];

      if (prevStep) {
        setStep(prevStep);
      }
    }
  };

  const prevStepRef = React.useRef<AddBusinessSteps | undefined>(undefined);

  useEffect(() => {
    prevStepRef.current = step;
  }, [step]);

  const legalStructureItems = React.useMemo(() => {
    const items = [
      {
        id: "notIncorporated",
        value: LegalStructureEnum.NOT_INCORPORATED,
        label: "I have not started my business yet",
      },
      {
        id: "llc",
        value: LegalStructureEnum.LLC,
        label: "LLC",
      },
      {
        id: "soleProprietorship",
        value: LegalStructureEnum.SOLE_PROPRIETORSHIP,
        label: "Sole proprietorship",
      },
      {
        id: "partnership",
        value: LegalStructureEnum.PARTNERSHIP,
        label: "Partnership",
      },

      {
        id: "sCorp",
        value: LegalStructureEnum.S_CORPORATION,
        label: "S corp",
      },
      {
        id: "cCorp",
        value: LegalStructureEnum.C_CORPORATION,
        label: "C corp",
      },
    ];
    return items;
  }, []);

  return (
    <AddBusinessContext.Provider
      value={{
        step,
        handleNextStep,
        handlePrevStep,
        legalStructureItems,
        resetStep: () => setStep(AddBusinessSteps.BUSINESS_NAME),
        prevStep: prevStepRef.current,
        selectedBusiness,
        setSelectedBusiness,
      }}
    >
      {children}
    </AddBusinessContext.Provider>
  );
};

export const useAddBusiness = () => {
  return useContext(AddBusinessContext);
};
